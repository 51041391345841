import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS AN FHA LOAN?",
      answer: (
        <>
          <p className="mb-0">
            An FHA loan is one that’s insured by the Federal Housing
            Administration (FHA). The FHA doesn’t lend you the money, you get it
            from a bank or private lender. The FHA simply insures the loan to
            protect lenders against losses, such as a property owner defaulting
            on their mortgage.
          </p>
        </>
      ),
    },
    {
      question: "HOW DO FHA HOME LOANS WORK?",
      answer: (
        <>
          <p className="mb-0">
            FHA loans are very similar to conventional loans. You’ll apply for a
            loan with a bank or lender that’s FHA-approved (many are) and will
            go through the same process to obtain a loan. The difference is that
            it’s typically easier to qualify for an FHA loan than it is for a
            conventional loan.
          </p>
        </>
      ),
    },
    {
      question: "WHAT ARE THE DIFFERENT TYPES OF FHA LOANS?",
      answer: (
        <>
          <p>
            Many home buyers get a traditional FHA loan, but there are other FHA
            loan types that might better fit your situation:
          </p>
          <ul className="list-disc pl-6">
            <li>Home Improvement Loan</li>
            <li>Graduated Payment Mortgage</li>
            <li>Reverse Mortgage</li>
            <li>Energy Efficient Mortgage </li>
          </ul>
        </>
      ),
    },
    {
      question: "FHA vs. CONVENTIONAL: WHAT’S THE DIFFERENCE?",
      answer: (
        <>
          <p className="mb-0">
            FHA mortgages are backed by the government (the Federal Housing
            Administration), while conventional loans are not. FHA loans have
            more relaxed credit and financial requirements than conventional
            loans, making them a popular option among home buyers.
          </p>
        </>
      ),
    },
    {
      question: "WHAT ARE THE FHA LOAN REQUIREMENTS?",
      answer: (
        <>
          <p className="mb-0">
            Aside from the credit score, down payment, and income requirements
            we’ve listed previously on this page, FHA loans also require that
            you:
          </p>
          <ul className="list-disc pl-6">
            <li>Get an appraisal by an FHA-approved appraiser</li>
            <li>Get an inspection of the property</li>
            <li>Plan to live in the home as your primary residence</li>
            <li>Move in within 60 days of closing</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT ARE THE ADVANTAGES OF AN FHA LOAN?",
      answer: (
        <>
          <p className="mb-0">There are many benefits, including:</p>
          <ul className="list-disc pl-6">
            <li>Low down payment</li>
            <li>Flexible credit score and debt-to-income (DTI) requirements</li>
            <li>Low interest rates</li>
            <li>Sellers can contribute to closing costs</li>
            <li>Gift funds are allowed for the down payment</li>
            <li>Just about every lender offers FHA loans</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT ARE THE DOWNSIDES OF AN FHA LOAN?",
      answer: (
        <>
          <p className="mb-0">
            Like any type of loan, FHA loans do have some disadvantages,
            including:
          </p>
          <ul className="list-disc pl-6">
            <li>Mortgage insurance is required</li>
            <li>There are FHA loan limits for each county in the U.S.</li>
            <li>
              They may not be as attractive to sellers (because of the
              inspection standards that may result in the seller having to
              complete repairs prior to closing)
            </li>
            <li>
              You can’t buy a vacation home or investment property with an FHA
              loan
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "CAN I GET AN FHA LOAN IF I HAVE STUDENT LOAN DEBT?",
      answer: (
        <>
          <p className="mb-0">
            Yes, you can. Your student loan debt is, however, included in your
            monthly debts, and if it pushes your debt-to-income ratio too high,
            you might not qualify for a mortgage.
          </p>
        </>
      ),
    },
    {
      question: "IS THERE MORTGAGE INSURANCE ON AN FHA LOAN?",
      answer: (
        <>
          <p className="mb-0">
            Yes, you are required to have mortgage insurance (unless you put
            down 20 percent or more on your home purchase).
          </p>
        </>
      ),
    },
    {
      question: "IS IT EASY TO GET AN FHA LOAN?",
      answer: (
        <>
          <p className="mb-0">
            FHA loans are one of the most popular types of loans because they’re
            easier to qualify for than conventional loans. Even those with
            blemished credit or low scores can qualify.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="FHA Loans Pasadena | Home Loans | Accel Mortgage Brokers"
        description="An FHA loan is one of the most popular types of home loans in Pasadena because it’s one of the easiest to qualify for & has a low down payment. Learn more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>FHA Loan: The Popular Choice</h1>
              <p>
                An FHA loan, insured by the Federal Housing Administration, is
                one of the most popular types of home loans because it’s one of
                the easiest to qualify for and comes with a low down payment.
                It’s a great way to get the keys to your new California home.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 FHA Loans/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Benefits of FHA Home Loans</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">
                  Easier Credit Qualifications
                </h3>
                <p className="mb-0">
                  FHA loans have lower credit score requirements, opening them
                  up to those who are credit-challenged.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Low Down Payment</h3>
                <p className="mb-0">
                  Buyers can put down as little as 3.5% when purchasing a home
                  using an FHA loan.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Buy, Build or Renovate</h3>
                <p className="mb-0">
                  You can buy an existing home, build one from the ground up, or
                  renovate a fixer-upper.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  Avoid Private Mortgage Insurance
                </h3>
                <p className="mb-0">
                  With a 20% down payment, you’re not required to pay private
                  mortgage insurance (PMI).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Who’s Eligible?</h2>
              <p>
                With an FHA mortgage, the home you purchase must be your primary
                residence (you can’t purchase an investment property or second
                home). To qualify, you’ll need:
              </p>
              <ul className="styled-list-arrow">
                <li>
                  A down payment of 3.5% (with a credit score of 580 or higher)
                </li>
                <li>
                  A down payment of 10% (with a credit score of 500 - 579)
                </li>
                <li>A debt-to-income ratio of no greater than 43%</li>
                <li>A steady income and proof of employment</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 FHA Loans/FHA.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
